import React from 'react';

import dataWhite from '../../../assets/img/svg/dataWhite.svg';
import ButtonLarge from '../../Buttons/ButtonLarge';
import './style.scss';
const SmartManageSection = () => {
  return (
    <section id="smart-manage">
      <div style={{ flexFlow: 'row', gap: '20px' }}>
        <img
          src={dataWhite}
          alt="Soluções"
          className="img-1"
          style={{ '--delay': '0.4s' }}
        />
        <h2 style={{ '--delay': '0.6s' }}>Soluções</h2>
      </div>
      <div
        className="mobile-column"
        style={{
          flexFlow: 'row',
          gap: '63px',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <div
          className="box"
          style={{
            border: 'solid 1px white',
            width: '40%',
            borderRadius: '15px',
            padding: '40px 25px',
          }}
        >
          <h2 style={{ '--delay': '0.8s', fontSize: '28px' }}>
            Indústrias de Alimentos
          </h2>
          <p style={{ '--delay': '1s' }}>
            Mapeamento dos operadores por tipologia e metodologia de gestão
            colaborativa - GID® - entre indústrias e distribuidores com
            soluções de gestão estratégica e operacional baseando-se nas
            principais alavancas de crescimento como numérica, mix e frequência.
          </p>
        </div>
        <div
          className="box"
          style={{
            border: 'solid 1px white',
            width: '40%',
            borderRadius: '15px',
            padding: '40px 25px',
          }}
        >
          <h2 style={{ '--delay': '0.8s', fontSize: '28px' }}>
            Distribuidoras de Foodservice
          </h2>
          <p style={{ '--delay': '1s' }}>
            Direcionamento com as melhores estratégias para aumento da carteira
            de clientes de modo a reduzir seu custo logístico, além de otimizar
            a execução com o mix correto por tipologia e aumento de frequência
            de compras.
          </p>
        </div>
      </div>

      {/* <ButtonLarge isTransparent /> */}
    </section>
  );
};

export default SmartManageSection;
