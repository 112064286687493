import React from 'react';
import icon from '../../assets/img/svg/fone.svg';
import Button from './Button';
const ButtonLarge = ({ isTransparent = false, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className={`btn ${isTransparent ? 'transparent' : 'orange'}`}
    >
      <img src={icon} alt="Fale conosco" />
      Agendar uma demonstração
    </Button>
  );
};

export default ButtonLarge;
