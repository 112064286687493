import React from 'react';
import ButtonLarge from '../../Buttons/ButtonLarge';
import Label from '../../content/Label/Label';
import './style.scss';
import chef from '../../../assets/img/about-us/chef.png';
import folha from '../../../assets/img/about-us/folha.png';
import folha2 from '../../../assets/img/about-us/folha-2.png';
import onion from '../../../assets/img/about-us/onion.png';
import onion2 from '../../../assets/img/about-us/onion-2.png';
import data from '../../../assets/img/svg/data.svg';

const AboutUsSection = () => {
  return (
    <section id="about-us" className="about-us up-anim">
      <article>
        <Label>Quem somos</Label>
        <h3 style={{ '--delay': '0.8s' }}>
          Juntamos tecnologia, Inteligência e conhecimento em gastronomia
        </h3>
        <p style={{ '--delay': '1s' }}>
          A Wise Sales é uma empresa jovem, inovadora e arrojada, que nasceu com
          o propósito de levar mais eficiência para a cadeia de abastecimento do
          Foodservice, através de inteligência de dados.
        </p>
      </article>
      <figure className="group">
        <picture className="chef" style={{ '--delay': '1.2s' }}>
          <img src={chef} alt="chef" />
        </picture>
        <picture className="onion" style={{ '--delay': '1.7s' }}>
          <img src={onion} alt="cebola" />
        </picture>
        <picture className="folha" style={{ '--delay': '1.9s' }}>
          <img src={folha} alt="folha" />
        </picture>
        <picture className="onion-2" style={{ '--delay': '1.8s' }}>
          <img src={onion2} alt="cebola" />
        </picture>
        <picture className="folha-2" style={{ '--delay': '2.1s' }}>
          <img src={folha2} alt="folha" />
        </picture>
        <div className="shape" />
        <figcaption>
          <figure style={{ '--delay': '3.5s' }}>
            <img src={data} alt="" style={{ '--delay': '3.7s' }} />
            <figcaption>
              <span style={{ '--delay': '3.7s' }}>Categorias</span>
              <span style={{ '--delay': '3.9s' }}>+600</span>
            </figcaption>
          </figure>
        </figcaption>
      </figure>
    </section>
  );
};

export default AboutUsSection;
