import React, { useState } from 'react';
import axios from 'axios';
import icon from '../../../../assets/img/svg/fone.svg';
const Form = () => {
  const [text, setText] = useState('Agendar uma demonstração');

  const send = async (event) => {
    event.preventDefault();
    setText('Enviando...');
    const form = document.getElementById('form_send');
    let data = new FormData(form);

    const options = {
      method: 'POST',
      url: 'https://wisesales.com.br/api/api-email.php',
      data: data,
    };

    await axios
      .request(options)
      .then(() => {
        form.reset();
        window.gtag('event', 'form_send')

        setTimeout(() => {
          setText('Mensagem enviada!');
        }, 1000);
      })
      .catch((error) => {
        console.error(error)
        setText('Revise os campos!')
      });
  };

  return (
    <form id="form_send">
      <label htmlFor="name">Nome</label>
      <input type="text" name="name" id="name" />
      <label htmlFor="empresa">Empresa</label>
      <input type="text" name="empresa" id="empresa" />
      <label htmlFor="cargo">Cargo</label>
      <input type="text" name="cargo" id="cargo" />
      <label htmlFor="email">E-mail</label>
      <input type="email" name="email" id="email" />
      <label htmlFor="telefone">Telefone</label>
      <input type="text" name="telefone" id="telefone" />
      {/* <ButtonLarge onClick={send} /> */}
      <button type="submit" className="btn orange" onClick={send}>
        <img src={icon} alt="Fale conosco" />
        {text}
      </button>
    </form>
  );
};

export default Form;
