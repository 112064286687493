import React from 'react';

import ButtonLarge from '../../Buttons/ButtonLarge';
import './style.scss';
import Topic from './Topic/Topic';
import img001 from '../../../assets/img/how-work/001.png';
import img002 from '../../../assets/img/how-work/002.png';
import img003 from '../../../assets/img/how-work/003.png';
const HowWorkSection = () => {
  const topics = [
    {
      image: img001,
      color: 'green',
      label: 'Crescimento',
      title: 'Mapeamento de oportunidades',
      description:
        '<ul><li>Análise de perfil de clientes;</li><li>Projeção de potencial por categoria, geografia e tipologia;</li><li>Adequação da malha de distribuidores;</li></ul>',
    },
    {
      image: img002,
      color: 'blue',
      label: 'Colaborativo',
      title: 'Acompanhamento gerencial e tático',
      description:
        '<ul><li>Insights para aumento de cobertura (DN), levando-se em consideração o perfil de clientes e área de atendimento;</li><li>Análise de categorias (sortimento), com lacunas e oportunidades para aumento de mix;</li><li>Análise de frequência de compras (comportamento), com lacunas e oportunidades para fidelização de clientes.</li></ul>',
    },
    // {
    //   image: img003,
    //   color: 'marrom',
    //   label: 'Estratégia',
    //   title: 'Acompanhamento tático (Insights para planos de ação)',
    //   description:
    //     'Fornecemos insights valiosos para cobertura (distribuição numérica), recorrência, aumento de mix e ticket médio. Com base nessas informações, você poderá operacionalizar ações promocionais e programas de fidelização direcionados, garantindo um impacto significativo nos resultados.',
    // },
  ];
  return (
    <>
      <section id="how-work">
        <h2 style={{ '--delay': '0.6s' }}>Como Funciona ?</h2>
        <span>
          identificando oportunidades de melhoria e impulsionando o crescimento.
        </span>
      </section>
      <div className="topics">
        {topics.map((topic, index) => (
          <section className="tpc" id={`topic-${index}`} key={index}>
            <Topic reverse={index % 2 !== 0} {...topic} />
          </section>
        ))}
      </div>
    </>
  );
};

export default HowWorkSection;
