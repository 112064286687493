import React from 'react';
import ButtonLarge from '../../../Buttons/ButtonLarge';
import './style.scss';
import Label from '../../../content/Label/Label';
const Topic = ({ image, title, description, reverse, color, label }) => {
  return (
    <figure className={`topic ${reverse ? 'reverse' : ''}`}>
      <img src={image} alt={title} style={{ '--delay': '0.4s' }} />
      <figcaption>
        <Label style={{ '--delay': '.6s' }} color={color}>
          {label}
        </Label>
        <h3 style={{ '--delay': '0.8s' }}>{title}</h3>
        <p
          style={{ '--delay': '1s' }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <ButtonLarge />
      </figcaption>
    </figure>
  );
};

export default Topic;
