import React from 'react';
import Header from '../components/header/Header';
import ClientsSection from '../components/sections/Clients/ClientsSection';
import FirstSection from '../components/sections/First/FirstSection';
import SmartManageSection from '../components/sections/SmartManage/SmartManageSection';
import HowWorkSection from '../components/sections/HowWork/HowWorkSection';
import AboutUs from '../components/sections/AboutUs/AboutUs';
import AboutUsSection from '../components/sections/AboutUs/AboutUs';
import WhyChooseSection from '../components/sections/WhyChoose/WhyChoose';
import ContactSection from '../components/sections/Contact/Contact';
import Footer from '../components/footer/Footer';
import Divider from '../components/content/Divider';

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <FirstSection />
        <ClientsSection />
        <SmartManageSection />
        <HowWorkSection />
        <AboutUsSection />
        <WhyChooseSection />
        <ContactSection />
      </main>
      <Divider />
      <Footer />
    </>
  );
};

export default Home;
