import React from 'react';
import ButtonLarge from '../../Buttons/ButtonLarge';
import './style.scss';
import icon1 from '../../../assets/img/why/001.svg';
import icon2 from '../../../assets/img/why/002.svg';
import icon3 from '../../../assets/img/why/003.svg';
const WhyChooseSection = () => {
  const reasons = [
    {
      icon: icon1,
      title: 'A maior e mais confiável base de dados do Foodservice',
      description:
        'Desenvolvemos uma tecnologia que busca, cruza, qualifica e organiza dados públicos dos estabelecimentos do setor de Foodservice, assegurando a melhor assertividade nos dados!',
    },
    {
      icon: icon2,
      title: 'Foco no Foodservice',
      description:
        'Entendemos os desafios e a fragmentação do mercado. Mapeamos cerca de 700 mil estabelecimentos ativos no Foodservice. Classificando por geografia, tipologia, cardápio dos estabelecimentos, potencial de compra e preço médio.',
    },
    {
      icon: icon3,
      title: 'Acompanhamento gerencial',
      description:
        'Disponibilizamos acesso à nossa equipe de especialistas em inteligência de dados para acompanhamento, elaboração de relatórios personalizados e insights de negócios.',
    },
  ];
  return (
    <section id="why-choose">
      <h2 style={{ '--delay': '0.6s' }}>Por que escolher a Wise?</h2>
      <span>
        A primeira plataforma de inteligência de mercado focada em foodservice
      </span>
      <ul>
        {reasons.map((reason, index) => (
          <li key={index} style={{ '--delay': `${index + 1}s` }}>
            <img src={reason.icon} alt="" />
            <h3>{reason.title}</h3>
            <p>{reason.description}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default WhyChooseSection;
