import React from 'react';
import './style.scss';
import pinFood from '../../../../assets/img/svg/pin_food.svg';
const FloatPin = ({ image, x, y, delay }) => {
  return (
    <figure
      className="float-pin"
      style={{ '--delay': `${delay * 0.1}s`, '--x': `${x}%`, '--y': `${y}%` }}
    >
      <img src={pinFood} alt="Restaurante" className="img-1" />
      <figcaption style={{ '--delay': `${delay - 4}s` }}>
        <img src={image} alt="Restaurante" />
      </figcaption>
    </figure>
  );
};

export default FloatPin;
