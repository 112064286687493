import React from 'react';
import company001 from '../../../assets/img/clients/001.png';
import company002 from '../../../assets/img/clients/002.png';
import company003 from '../../../assets/img/clients/003.png';
import company004 from '../../../assets/img/clients/004.png';
import company005 from '../../../assets/img/clients/005.png';
import company006 from '../../../assets/img/clients/006.png';
import company007 from '../../../assets/img/clients/007.png';
import company008 from '../../../assets/img/clients/008.png';
import company011 from '../../../assets/img/clients/011.png';
import company012 from '../../../assets/img/clients/012.png';
import company013 from '../../../assets/img/clients/013.png';
import company014 from '../../../assets/img/clients/014.png';
import company015 from '../../../assets/img/clients/015.png';
import company016 from '../../../assets/img/clients/016.png';
import './style.scss';
const ClientsSection = () => {
  const images = [
    company001,
    company002,
    company003,
    company004,
    company005,
    company006,
    company007,
    company008,
    company011,
    // company012,
    company013,
    company014,
    company015,
    company016,
  ];
  const getList = (delay = 0) => {
    return images.map((image, index) => (
      <li
        key={index}
        className="up-anim"
        style={{ '--delay': `${(index + delay * images.length) * 0.1}s` }}
      >
        <img src={image} alt="Avaliação 4.9" className="img-1" />
      </li>
    ));
  };
  return (
    <section id="clients">
      <ul style={{ '--count': `${images.length}` }}>
        {getList()}
        {/* Duas vezes para fazer o efeito infinito */}
        {getList(1)}
      </ul>
    </section>
  );
};

export default ClientsSection;
