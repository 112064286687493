import React from 'react';

const Divider = () => {
  return (
    <div
      style={{
        height: '1px',
        background: 'rgba(85, 157, 92, 0.30)',
        width: '100%',
      }}
    />
  );
};

export default Divider;
