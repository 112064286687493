import React from 'react';
import ButtonSmall from '../Buttons/ButtonSmall';
import './style.scss';
const Header = () => {
  return (
    <header>
      <span className="logo">Wise Sales</span>
      <nav>
        <ul>
          <li>
            <a href="#about-us">Sobre nós</a>
          </li>
          <li>
            <a href="#how-work">Veja como funciona</a>
          </li>
        </ul>
      </nav>
      <ButtonSmall />
    </header>
  );
};

export default Header;
