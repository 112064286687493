import React from 'react';
import './style.scss';
const Label = ({ children, color = 'green', ...props }) => {
  return (
    <span className={`label ${color}`} {...props}>
      {children}
    </span>
  );
};

export default Label;
