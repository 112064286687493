import React from 'react';
import icon from '../../assets/img/svg/fone.svg';
import Button from './Button';
const ButtonSmall = () => {
  return (
    <Button>
      <img src={icon} alt="Fale conosco" />
      Fale conosco
    </Button>
  );
};

export default ButtonSmall;
