import React from 'react';
import './style.scss';
import logo from '../../assets/img/footer/logo.png';
import lambreta from '../../assets/img/footer/lambreta.svg';
import facebook from '../../assets/img/footer/facebook.svg';
import instagram from '../../assets/img/footer/instagram.svg';
import linkedin from '../../assets/img/footer/linkedin.svg';
import pin from '../../assets/img/footer/pin.svg';
import send from '../../assets/img/footer/send.svg';
import tel from '../../assets/img/footer/tel.svg';
import Divider from '../content/Divider';
const Footer = () => {
  const social = [
    {
      icon: facebook,
      alt: 'Facebook',
    },
    {
      icon: instagram,
      alt: 'Instagram',
    },
    {
      icon: linkedin,
      alt: 'Linkedin',
    },
  ];
  const infos = [
    {
      icon: pin,
      alt: 'Av. Onze de Agosto, 2204 - Vila Embare, Valinhos - SP, 13271-210',
      link: 'https://www.google.com.br/maps/place/Av.+Onze+de+Agosto,+2204+-+Vila+Embare,+Valinhos+-+SP,+13271-210/@-22.9657215,-47.0072306,18.75z/data=!4m6!3m5!1s0x94c8cdc71d5fffe1:0xf94121d9e60e84f7!8m2!3d-22.9658015!4d-47.0065732!16s%2Fg%2F11cnclw7n2?entry=ttu',
    },
    {
      icon: send,
      alt: 'contato@wisesales.com.br',
      link: 'mailto:contato@wisesales.com.br',
    },
    {
      icon: tel,
      alt: '(11) 9 8677-7878',
      link: 'tel:+5511986777878',
    },
  ];
  return (
    <footer>
      <section className="brand">
        <figure>
          <figcaption>
            <img src={logo} alt="Wise Sales" />
            <span>
              <strong>+900 mil</strong> estabelecimentos Atualizados Mensalmente
            </span>
          </figcaption>
          <img src={lambreta} alt="Wise Sales" />
        </figure>
        {/* <ul>
          {social.map((item, index) => (
            <li key={index}>
              <a href="#">
                <img src={item.icon} alt={item.alt} />
              </a>
            </li>
          ))}
        </ul> */}
      </section>
      {/* <section className="infos">
        <h4>Onde você pode nos encontrar</h4>
        <ul>
          {infos.map((item, index) => (
            <li key={index}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img src={item.icon} alt={item.alt} />
                <span>{item.alt}</span>,
              </a>
            </li>
          ))}
        </ul>
      </section> */}
      <Divider />
      <span>Copyright 2023. Wise Sales © Todos os direitos reservados</span>
    </footer>
  );
};

export default Footer;
