import React from 'react';
import icon from '../../../assets/img/svg/mouse.svg';
import rest001 from '../../../assets/img/first/001.png';
import rest002 from '../../../assets/img/first/002.png';
import rest003 from '../../../assets/img/first/003.png';

import './style.scss';
import FloatPin from './FloatPin/FloatPin';
const FirstSection = () => {
  const pins = [
    {
      image: rest001,
      x: 50,
      y: 80,
    },
    {
      image: rest002,
      x: 60,
      y: 36,
    },
    {
      image: rest003,
      x: 74,
      y: 70,
    },
  ];
  return (
    <section id="first" className="visible">
      <h1 style={{ '--delay': '.1s' }}>
        Decisões assertivas no setor de <strong>Foodservice</strong>
      </h1>
      <p style={{ '--delay': '.3s' }}>
        {' '}
        Wise Sales é a plataforma de inteligência de dados que as equipes de
        marketing e vendas das maiores indústrias de alimentos utilizam para
        operar de forma eficiente no setor de Foodservice
      </p>
      <a href="#clients" className="down" style={{ '--delay': '.6s' }}>
        <img src={icon} alt="arrow-down" />
        Veja como funciona
      </a>
      <aside>
        {pins.map((pin, index) => (
          <FloatPin
            key={index}
            image={pin.image}
            x={pin.x}
            y={pin.y}
            delay={6 + index * 2}
          />
        ))}
      </aside>
    </section>
  );
};

export default FirstSection;
