import React from 'react';
import ButtonLarge from '../../Buttons/ButtonLarge';
import './style.scss';
import img from '../../../assets/img/contact/001.png';
import person from '../../../assets/img/contact/002.png';
import icon from '../../../assets/img/contact/003.svg';
import Label from '../../content/Label/Label';
import Form from './Form/Form';
const ContactSection = () => {
  return (
    <section id="contact">
      <article>
        <Label>Nós ligamos para você</Label>
        <h2 style={{ '--delay': '0.6s' }}>
          Inteligência de mercado especializada em Foodservice
        </h2>
        <p>
          Preencha o formulário abaixo para receber mais informações e agendar
          uma demonstração personalizada com um de nossos especialistas.
        </p>
        <figure>
          <img src={img} alt="Falamos com você" />
          <figcaption>
            <img src={person} className="person" alt="Falamos com você" />
            <article>
              <strong>Falamos com você</strong>
              <span>Time Wise Sales</span>
            </article>
            <img src={icon} className="icon" alt="Falamos com você" />
          </figcaption>
        </figure>
      </article>
      <Form />
    </section>
  );
};

export default ContactSection;
